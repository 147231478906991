import ReclamationService from '@/services/ReclamationService'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: any = {
    reclamation_admissibilite: null,
    epreuves_admissibilite: [],
    concour_admissibilite: null,
    reclamation_admission: null,
    epreuves_admission: [],
    concour_admission: null,
    reclamation_postconcours: null,
    epreuves_postconcours: [],
    concour_postconcours: null,
    error_reclamation: null,
    error_acces_reclamation: null,
    loading_reclamation: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            reclamation_admissibilite: null,
            epreuves_admissibilite: [],
            concour_admissibilite: [],
            reclamation_admission: null,
            epreuves_admission: [],
            concour_admission: null,
            reclamation_postconcours: null,
            epreuves_postconcours: [],
            concour_postconcours: null,
            error_reclamation: null,
            error_acces_reclamation: null,
            loading_reclamation: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_reclamation = error
    },
    SET_ERROR_ACCES(state: any, error: any) {
        state.error_acces_reclamation = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_reclamation = etat
    },
    SET_RECLAMATION_ADMISSIBILITE(state: any, reclamation: any) {
        if (reclamation) {
            state.reclamation_admissibilite = reclamation
        }
    },
    SET_EPREUVES_RECLAMATION_ADMISSIBILITE(state: any, epreuves: any) {
        if (epreuves.length !== 0) {
            state.epreuves_admissibilite = epreuves
        }
    },
    SET_CONCOUR_ADMISSIBILITE(state: any, concour: any) {
        state.concour_admissibilite = concour
    },
    SET_RECLAMATION_ADMISSION(state: any, reclamation: any) {
        if (reclamation) {
            state.reclamation_admission = reclamation
        }
    },
    SET_EPREUVES_RECLAMATION_ADMISSION(state: any, epreuves: any) {
        if (epreuves.length !== 0) {
            state.epreuves_admission = epreuves
        }
    },
    SET_CONCOUR_ADMISSION(state: any, concour: any) {
        state.concour_admission = concour
    },
    SET_RECLAMATION_POSTCONCOURS(state: any, reclamation: any) {
        if (reclamation) {
            state.reclamation_postconcours = reclamation
        }
    },
    SET_EPREUVES_RECLAMATION_POSTCONCOURS(state: any, epreuves: any) {
        if (epreuves.length !== 0) {
            state.epreuves_postconcours = epreuves
        }
    },
    SET_CONCOUR_POSTCONCOURS(state: any, concour: any) {
        state.concour_postconcours = concour
    }

}

export const actions = {
    getReclamations({ commit }: { commit: any }, phase: string) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamations(phase)
                .then((response: any) => {
                    if (phase === 'admissibilite') {
                        commit('SET_RECLAMATION_ADMISSIBILITE', response.data.data)
                    } else if (phase === 'admission') {
                        commit('SET_RECLAMATION_ADMISSION', response.data.data)
                    } else if (phase === 'postconcours') {
                        commit('SET_RECLAMATION_POSTCONCOURS', response.data.data)
                    }

                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response && error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }

                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    postReclamation({ commit }: { commit: any }, payload: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.postReclamation(payload.puid, payload.phase, payload.params)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getReclamationPDFCandidat({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getReclamationPDFCandidat(params.phase, params.puid, params.index)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getDecisionPDF({ commit }: { commit: any }, params?: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ReclamationService.getDecisionPDF(params.puid, params.phase, params.epreuve_id)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    get_epreuve_admissibilite: (state: any) => (epreuve_id: number) => {
        return state.epreuves_admissibilite.find((ea: any) => ea.id === epreuve_id)
    },
    get_epreuve_admission: (state: any) => (epreuve_id: number) => {
        return state.epreuves_admission.find((ea: any) => ea.id === epreuve_id)
    },
    get_epreuve_postconcours: (state: any) => (puid: number, epreuve_id: number) => {
        for (let i = 0; i < state.reclamation_postconcours.length; i++) {
            if (state.reclamation_postconcours[i].puid === puid) {
                return state.reclamation_postconcours[i].datas.epreuves.find((ea: any) => ea.id === epreuve_id)
            }
        }

        // return state.epreuves_postconcours.find((ea: any) => ea.id === epreuve_id)
    }
}

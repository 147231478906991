import ConvocationService from '@/services/ConvocationService'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: any = {
    publications_convocation_admissibilite: null,
    publications_convocation_admission: null,
    error_convocation: null,
    loading_convocation: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            publications_convocation_admissibilite: null,
            publications_convocation_admission: null,
            error_convocation: null,
            loading_convocation: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error_convocation = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading_convocation = etat
    },
    SET_CONVOCATION_ADMISSIBILITE(state: any, publication: any) {
        state.publications_convocation_admissibilite = publication
    },
    SET_CONVOCATION_ADMISSION(state: any, publication: any) {
        state.publications_convocation_admission = publication
    }
}

export const actions = {
    getConvocation({ commit }: { commit: any }, phase: any) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            ConvocationService.getConvocation(phase)
                .then((response: any) => {
                    if (phase === 'admission') {
                        commit('SET_CONVOCATION_ADMISSION', response.data.data)
                    } else {
                        commit('SET_CONVOCATION_ADMISSIBILITE', response.data.data)
                    }
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getPlanningPDF({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ConvocationService.getPlanningPDF(params.phase, params.puid)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    getDecisionAmenagementPDF({ commit }: { commit: any }, params: any) {
        return new Promise((resolve, reject) => {
            ConvocationService.getDecisionAmenagementPDF(params.phase, params.puid)
                .then((response: any) => {
                    resolve(response)
                })
                .catch((error: any) => {
                    // Dispatch les errors selon si erreur d'accés (403) ou erreur fonctionnelle
                    if (error.response.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    }
}

export const getters = {}


import { Component, Vue } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    }
})
export default class NavBarTop extends Vue {
    user = this.$store.getters['user/user_select']

    /**
     * Vérifie si la valeur est incluse dans la liste publishable_codes
     * @param {string} value - Valeur à vérifier
     * @returns {boolean} - True si la valeur est incluse dans la liste
     */
    includePublishable(value: string): boolean {
        if (this.user && this.user.publishable_codes && this.user.publishable_codes.length) {
            return this.user.publishable_codes.includes(value)
        }
        return false
    }

    /**
     * Déconnexion du candidat
     * @return {void}
     */
    logout(): void {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.commit('user/SET_USER_SELECT', null)
            window.location.href = this.$store.state.auth.back_url
        })
    }
}
